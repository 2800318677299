import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import ImagePortrait from "./imagePortrait-en"
import { FaGraduationCap } from "react-icons/fa"
const presentationHome = () => (
  <Wrapper className="submenu">
    <Col1>
      <ImagePortrait />
      <Button to="/en/overview/">
        <FaGraduationCap
          css={css`
            transform: translateY(2px);
            margin-right: 4px;
          `}
        />
        Qualifications and titles
      </Button>
    </Col1>

    <Col2>
      <H1>
        Orthopaedic surgeon in Paris, specialized exclusively in hand, elbow and
        shoulder surgery
      </H1>
      <p>
        I am an orthopaedic surgeon, specialized in hand surgery, microsurgery,
        arthroscopic surgery, and{" "}
        <Link to="/en/sports-surgery/">sports surgery</Link>. I have been
        exclusively practicing hand, wrist, elbow and shoulder surgery{" "}
        <strong>for over 20&nbsp;years</strong>.
      </p>

      <H2>A "tailor-made" approach to surgery</H2>

      <p>
        I favour a "tailor-made" approach, and seek both the most technically
        advanced solution and the one that best matches the expectations and
        profile of my patients. I combine my experience in the treatment of
        high-performance athletes with restorative surgery and microsurgery in
        order to offer a <strong>personalised response</strong> to each of my
        patients.
      </p>

      <H2>Optimal functional and aesthetic results</H2>
      <p>
        In my practice as an orthopaedic surgeon, I use the{" "}
        <strong>most recent and least invasive</strong> techniques, (
        <Link to="/en/technics/microsurgery/">microsurgery</Link>,{" "}
        <Link to="/en/technics/arthroscopy/">arthroscopy</Link>) in order to
        minimise pain and post-operative down-time, providing optimal functional
        and aesthetic results.
      </p>
      <p>
        I provide my consultations as well as my surgical activity in
        <strong>highly-specialised</strong> Parisian orthopaedic surgery and
        sports surgery centres: the{" "}
        <Link to="/en/consultation/">Espace Médical Vauban</Link> (Paris 7
        <sup>th</sup>) and the <Link to="/en/clinic/">Clinique du Sport</Link>{" "}
        (Paris 5<sup>th</sup>). I therefore have the very latest equipment, and
        work with colleagues who also specialise in trauma, sports medicine and
        orthopaedic surgery.
      </p>
      <p
        css={css`
          text-align: right;
          font-weight: 600;
          margin-top: 20px;
          @media (max-width: 678px) {
            font-size: 0.92em;
            margin: 10px 0 20px;
          }
        `}
      >
        Dr Philippe Roure - Orthopaedic surgeon Specialized in the upper limb in
        Paris.
      </p>
    </Col2>
  </Wrapper>
)

const Wrapper = styled.section`
  display: flex;
  background-color: white;
  border-radius: 5px;
  margin: 2.5em auto 0.5em;
  padding: 1.75em 1.2em 1em 1.5em;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 7px;
  @media (max-width: 678px) {
    flex-wrap: wrap;
    padding: 0.9em;
  }
`

const Col1 = styled.div`
  flex: 25%;
  padding: 10px 10px 0 0;
  @media (max-width: 678px) {
    order: 2;
    flex-basis: 100%;
    padding: 0;
  }
`

const Col2 = styled.div`
  flex: 75%;
  padding-left: 12px;
  @media (max-width: 678px) {
    order: 1;
    flex-basis: 100%;
    padding: 0;
  }
`

const H1 = styled.h1`
  text-align: left;
  font-size: 1.7em;
  line-height: 1.4em;
  @media (max-width: 678px) {
    font-size: 1.5em;
    line-height: 1.3em;
  }
`

const H2 = styled.h2`
  font-size: 1.18em;
  opacity: 0.85;
  line-height: 1.5em;
  margin-top: 5px;
`

const Button = styled(Link)`
  display: block;
  clear: both;
  text-align: center;
  text-decoration: none;
  margin-top: 15px;
  padding: 0.6em 1.2em;
  background-color: #d69c31;
  cursor: pointer;
  color: white;
  font-weight: 600;
  font-size: 0.92em;
  letter-spacing: 0.035em;
  border-radius: 4px;
  transition: all 0.15s ease-in-out;
  :hover,
  :focus {
    color: white;
    background-color: rgba(44, 44, 44, 0.96);
    outline: none;
    transition: all 0.15s ease-in-out;
  }
  svg {
    font-size: 1.2em;
  }
  @media (max-width: 1024px) {
    padding: 0.5em 0.8em;
  }
`

export default presentationHome
