import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { css } from "@emotion/react"

export default () => (
  <StaticQuery
    query={graphql`
      query shoulderSurgeryAcccueilquery {
        file(relativePath: { eq: "chirurgie-epaule-accueil.png" }) {
          childImageSharp {
            fluid(maxWidth: 400) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    `}
    render={data => (
      <Img
        fluid={data.file.childImageSharp.fluid}
        alt="Shoulder surgery"
        css={css`
          margin-bottom: 1em;
          cursor: pointer;
        `}
      />
    )}
  />
)
