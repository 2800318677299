import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../../components/layout-en"
import BannerHome from "../../components/banner-home-en"
import PresentationHome from "../../components/presentationHome-en"
import InterventionsHome from "../../components/interventionsHome-en"
import ContactHome from "../../components/contact-home-en"
import ContactForm from "../../components/contactForm-en"
import SEO from "../../components/seo-en"

const IndexPage = () => (
  <Layout pathFr="/">
    <SEO
      title="Dr Philippe Roure - Orthopedic surgeon in Paris"
      description="Dr Roure is specialized in the surgery of the upper limb (hand, wrist, elbow shoulder) using microsurgery and arthroscopy surgery."
    />
    <Helmet>
      <link
        rel="alternate"
        hreflang="fr"
        href={`https://www.docteurphilipperoure.com/`}
      />
    </Helmet>
    <BannerHome />
    <PresentationHome />
    <InterventionsHome />
    <ContactHome />
    <ContactForm about="upper limb surgical procedures (hand, wrist, elbow and shoulder)" />
  </Layout>
)

export default IndexPage
