import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { Global, css } from "@emotion/react"
import { Tabs, Tab, TabPanel, TabList } from "react-web-tabs"
import ImageChirurgieEpauleAccueil from "./imageChirurgieEpauleAccueil-en"
import ImageChirurgieCoudeAccueil from "./imageChirurgieCoudeAccueil-en"
import ImageChirurgiePoignetAccueil from "./imageChirurgiePoignetAccueil-en"
import ImageChirurgieMainAccueil from "./imageChirurgieMainAccueil-en"

import "react-web-tabs/dist/react-web-tabs.css"

export default () => (
  <Wrapper>
    <Global
      styles={css`
        .rwt__tabs {
          margin: 1em 0;
        }
        .rwt__tab {
          width: 25%;
          padding: 2rem 3rem 1.25rem;
        }
        .rwt__tab[aria-selected="true"] {
          div:nth-child(2) {
            border-bottom: 2px solid #d69c31;
          }
        }
        .rwt__tab[aria-selected="false"] {
          filter: grayscale(100%);
        }
        .rwt__tab[aria-selected="false"]:hover,
        .rwt__tab:focus {
          background-color: inherit;
        }
        .rwt__tablist:not([aria-orientation="vertical"])
          .rwt__tab[aria-selected="true"]:after {
          border-bottom: none;
        }
        .rwt__tablist:not([aria-orientation="vertical"]) {
          border-bottom: none;
        }
        .rwt__tabpanel {
          padding: 0em 3em 2em;
          min-height: 275px;
          p {
            font-weight: 500;
          }
        }
        @media (max-width: 1024px) {
          .rwt__tab {
            padding: 2rem 1.5rem 1.25rem;
          }
        }
        @media (max-width: 678px) {
          .rwt__tabpanel {
            padding: 0;
            margin-top: 25px;
            p {
              font-weight: inherit;
            }
          }
          .rwt__tab {
            padding: 0 7px 0;
          }
          .rwt__tabs {
            margin-top: 35px;
          }
        }
      `}
    />
    <H2>
      <span>Surgeries</span> performed by Dr Roure
    </H2>
    <hr />
    <Tabs defaultTab="one">
      <TabList>
        <Tab tabFor="one">
          <ImageChirurgieEpauleAccueil />
          <Cat>Shoulder</Cat>
        </Tab>
        <Tab tabFor="two">
          {" "}
          <ImageChirurgieCoudeAccueil />
          <Cat>Elbow</Cat>
        </Tab>
        <Tab tabFor="three">
          <ImageChirurgiePoignetAccueil />
          <Cat>Wrist</Cat>
        </Tab>
        <Tab tabFor="four">
          <ImageChirurgieMainAccueil />
          <Cat>Hand</Cat>
        </Tab>
      </TabList>
      <TabPanel tabId="one">
        <P>
          Our understanding of shoulder injuries and the options available to
          treat them have made enormous progress over the past 15 years, thanks
          to imaging techniques such as MRI and arthrography, and the
          development of new arthroscopic surgery techniques.
        </P>
        <LinkList>
          <StyledLink to="/en/shoulder-surgery/acromio-clavicular-arthrosis/">
            Acromio-clavicular arthrosis
          </StyledLink>
          <StyledLink to="/en/shoulder-surgery/fracture-of-the-clavicle/">
            Fracture of the clavicle
          </StyledLink>
          <StyledLink to="/en/shoulder-surgery/shoulder-luxation/">
            Luxation of the shoulder
          </StyledLink>
          <StyledLink to="/en/shoulder-surgery/rupture-rotator-cuff-tendons/">
            Rupture of the rotator cuff tendons
          </StyledLink>
          <StyledLink to="/en/shoulder-surgery/sub-acromial-conflict/">
            Sub-acromial conflict
          </StyledLink>
          <StyledLink to="/en/shoulder-surgery/tendinopathy-rotator-cuff/">
            Tendinopathy of the rotator cuff
          </StyledLink>
        </LinkList>
        <LinkButton
          to="/en/shoulder-surgery/"
          title="Learn more about shoulder surgery"
        >
          Learn more
        </LinkButton>
      </TabPanel>
      <TabPanel tabId="two">
        <P>
          Each of us can develop chronic and disabling pain in the elbow, often
          due to epicondylitis, epitrochleitis or as a result of trauma.
        </P>
        <P>
          Loss of feeling or strength in the hand can also be caused by
          compression of the ulnar nerve at the elbow.
        </P>
        <P>
          Arthroscopic surgery techniques have also greatly improved treatment
          options.
        </P>
        <LinkList>
          <StyledLink to="/en/elbow-surgery/biceps-tendon-rupture/">
            Biceps tendon rupture
          </StyledLink>
          <StyledLink to="/en/elbow-surgery/epicondylitis/">
            Epicondylitis : Elbow tendonitis
          </StyledLink>
          <StyledLink to="/en/elbow-surgery/ulnar-nerve-entrapment/">
            Ulnar nerve entrapment
          </StyledLink>
        </LinkList>
        <LinkButton
          to="/en/elbow-surgery/"
          title="Learn more about elbow surgery in Paris"
        >
          Learn more
        </LinkButton>
      </TabPanel>
      <TabPanel tabId="three">
        <P>
          Many patients complain of wrist pain that is either chronic or the
          result of trauma such as a wrist fracture, with no precise diagnosis
          other than "sprain" and which nevertheless greatly limits the
          practising of sports, leisure and professional activities, in addition
          to causing pain.
        </P>
        <P>But there are solutions!</P>
        <LinkList>
          <StyledLink to="/en/wrist-surgery/arthroscopy-of-the-wrist/">
            Arthroscopy of the wrist
          </StyledLink>
          <StyledLink to="/en/wrist-surgery/de-quervain-syndrom/">
            De Quervain syndrome
          </StyledLink>
          <StyledLink to="/en/hand-surgery/synovial-cyst/">
            Synovial cyst
          </StyledLink>
        </LinkList>
        <LinkButton
          to="/en/wrist-surgery/"
          title="Learn more about wrist surgery in Paris"
        >
          Learn more
        </LinkButton>
      </TabPanel>
      <TabPanel tabId="four">
        <P>
          Hand surgery remains a separate discipline within orthopaedic surgery.
          The hand is actually an essential organ both functionally and
          socially.
        </P>
        <P>
          The hand is an anatomical area where many different, mobile structures
          such as nerves, tendons and vessels coexist in a confined space.
        </P>
        <P>
          Hand surgery requires specific and highly-specialised know-how. A
          command of microsurgery techniques is essential for any hand surgery
          operation.
        </P>
        <LinkList>
          <StyledLink to="/en/nerves-surgery/carpal-tunnel-syndrome/">
            Carpal tunnel syndrome
          </StyledLink>
          <StyledLink to="/en/hand-surgery/dupuytren-s-contracture/">
            Dupuytren's contracture
          </StyledLink>
          <StyledLink to="/en/hand-surgery/hand-emergency/">
            Hand emergency
          </StyledLink>
          <StyledLink to="/en/hand-surgery/hand-wounds/">
            Hand wounds emergency
          </StyledLink>
          <StyledLink to="/en/hand-surgery/mucous-cysts-fingers/">
            Mucous cysts of the fingers
          </StyledLink>
          <StyledLink to="/en/hand-surgery/rhizarthrosis/">
            Rhizarthrosis
          </StyledLink>
          <StyledLink to="/en/hand-surgery/trigger-finger/">
            Trigger finger
          </StyledLink>
          <StyledLink to="/en/elbow-surgery/ulnar-nerve-entrapment/">
            Ulnar nerve entrapment
          </StyledLink>
        </LinkList>
        <LinkButton
          to="/en/hand-surgery/"
          title="Learn more about hand surgery"
        >
          Learn more
        </LinkButton>
      </TabPanel>
    </Tabs>
  </Wrapper>
)

const Wrapper = styled.div`
  background-color: white;
  border-radius: 5px;
  margin: 2.5em auto 1em;
  padding: 1.5em 1.5em 1em 1.5em;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 7px;
  @media (max-width: 678px) {
    padding: 1em;
  }
`

const H2 = styled.h2`
  text-align: center;
  padding-top: 0.25em;
  font-weight: 400;
  font-size: 2.4em;
  margin-block-start: 0;
  span {
    font-weight: 800;
  }
  @media (max-width: 678px) {
    font-size: 2.2em;
  }
`

const Cat = styled.div`
  font-size: 1.4em;
  letter-spacing: 0.02em;
  line-height: 1.4em;
  font-variant: small-caps;
  letter-spacing: 0.03em;
  font-weight: 600;
  width: 45%;
  margin: 0 auto;
  padding-bottom: 6px;
  @media (max-width: 678px) {
    width: 100%;
    font-size: 1.2em;
  }
`

const P = styled.p`
  font-size: 1.1em;
  line-height: 1.7em;
  @media (max-width: 678px) {
    font-size: 1em;
  }
`

const LinkList = styled.div`
  width: 80%;
  text-align: center;
  margin: 15px auto 10px;
  @media (max-width: 678px) {
    width: 100%;
  }
`

const StyledLink = styled(Link)`
  text-decoration: none;
  color: #d69c31;
  border-botton: 0.08em solid #5a5a5a;
  font-weight: 500;
  font-size: 0.94em;
  ::after {
    content: "•";
    color: #171717;
    margin: 0 5px 0;
  }
  :last-of-type {
    ::after {
      content: "";
    }
  }
  :hover,
  :focus {
    color: #171717;
  }
  @media (max-width: 678px) {
    display: block;
    line-height: 1.45em;
    margin-bottom: 10px;
    ::after {
      content: none;
    }
  }
`

const LinkButton = styled(Link)`
  display: block;
  margin: 20px auto 0;
  text-align: center;
  font-weight: 600;
  width: 125px;
  font-size: 14px;
  letter-spacing: 0em;
  background-color: black;
  color: white;
  text-decoration: none;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(218, 218, 218);
  border-image: initial;
  padding: 0.35em 0.55em;
  border: 1px solid black;
  :focus,
  :hover {
    border: 1px solid #d69c31;
    background-color: white;
    color: #d69c31;
    box-shadow: rgba(230, 174, 72, 0.15) 0px 1px 7px;
    outline: none;
  }
`
